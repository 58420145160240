'use client';

import React, { useEffect, useRef, useState } from 'react';

import mapboxClient from '@mapbox/mapbox-sdk';
import mapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import cx from 'classnames';
import { useRouter } from 'next-nprogress-bar';
import { usePathname, useSearchParams } from 'next/navigation';
import { parseAsFloat, parseAsString, useQueryStates } from 'nuqs';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import ButtonCircle from 'components/ui/ButtonCircle';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import { useLocation } from 'hooks/useLocation';
import useMediaQuery from 'hooks/useMediaQuery';

import styles from './Search.module.scss';

const MAPBOX_TOKEN =
    'pk.eyJ1IjoiaGF1c2xhIiwiYSI6ImNrbHN0dHRzNjA1d24yd25xZWhka2xmMm8ifQ.C3nu0o6XmETb877Vj6tjfQ';

const Search = ({ className }) => {
    const ref = useRef();
    const [userLocation] = useLocation();

    const searchParams = useSearchParams();

    const allSearchParams = Object.fromEntries(searchParams.entries());

    const [search, setSearch] = useState('');
    const [result, setResult] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [prevGeolocationAccuracy, setPrevGeolocationAccuracy] =
        useState(null);

    const isMedium = useMediaQuery('(min-width: 768px)');
    const router = useRouter();
    const disableSearch = result?.length === 0 || search?.length === 0;

    const [query, updateQuery] = useQueryStates({
        input: parseAsString.withDefault(''),
        lat: parseAsFloat.withDefault(0),
        lon: parseAsFloat.withDefault(0),
        type: parseAsString.withDefault(''),
    });

    const pathname = usePathname();

    const handleSubmit = result => {
        if (pathname !== '/where-to-eat/map') return;

        updateQuery(result);
        setSearch(result.input);
    };

    const getGeocoding = async () => {
        await new Promise(resolve => {
            search && resolve();
        });
        mapboxGeocoding(mapboxClient({ accessToken: MAPBOX_TOKEN }))
            .forwardGeocode({
                query: search,
                countries: ['us'],
                types: [
                    'region',
                    'postcode',
                    'district',
                    'place',
                    'neighborhood',
                    'address',
                    'poi',
                ],
                limit: 5,
            })

            .send()
            .then(res => {
                const {
                    body: { features },
                } = res;
                setResult(features);
            });
    };

    useEffect(() => {
        getGeocoding();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const initLocation = () => {
        const { city, state, postalCode, coordinates } = userLocation || {};

        const initValues = postalCode
            ? `${city}, ${state} ${postalCode}`
            : `${city}, ${state}`;

        pathname === '/where-to-eat'
            ? setSearch(initValues)
            : handleSubmit({
                  input: initValues,
                  lat: coordinates[1],
                  lon: coordinates[0],
              });
    };

    useEffect(() => {
        setSearch(query.input);

        const updatedGeolocationAccuracy =
            prevGeolocationAccuracy &&
            prevGeolocationAccuracy !== userLocation?.accuracy;

        if (updatedGeolocationAccuracy) {
            initLocation();
        }

        const queryLoaded = Object.keys(allSearchParams).includes(
            'input' || ('lat' && 'lon')
        );

        if (queryLoaded) return;

        if (!queryLoaded && userLocation) {
            initLocation();
            setPrevGeolocationAccuracy(userLocation?.accuracy);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLocation]);

    const handleSearch = evt => {
        setSearch(evt.target.value);
    };

    const renderSubmitContainer = () => {
        const showClose = search !== '' && isMedium;

        return (
            <div className={styles.submitContainer}>
                {showClose && (
                    <span
                        className={styles.closeIcon}
                        onClick={() => setSearch('')}
                    >
                        <SvgIcon type="close" />
                    </span>
                )}
                {isMedium ? (
                    <Button
                        onClick={() => {
                            pathname === '/where-to-eat'
                                ? router.push(
                                      `/where-to-eat/map?input=${search}&lon=${result[0].center[0]}&lat=${result[0].center[1]}&type=${result[0].place_type[0]}`
                                  )
                                : handleSubmit({ input: search });
                        }}
                        theme="none"
                        className={styles.textButton}
                        disabled={disableSearch}
                    >
                        <Text baseTheme="headingSmall2">Let&#8217;s Eat!</Text>
                    </Button>
                ) : (
                    <ButtonCircle
                        className={styles.circleButton}
                        theme="small-fill-blue"
                        iconType="arrowRightRounded"
                        disabled={disableSearch}
                        onClick={() => {
                            pathname === '/where-to-eat'
                                ? router.push(
                                      `/where-to-eat/map?input=${search}&lon=${result[0].center[0]}&lat=${result[0].center[1]}&type=${result[0].place_type[0]}`
                                  )
                                : handleSubmit({ input: search });
                        }}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <div className={cx(styles.root, className)}>
                <div className={styles.search}>
                    <Text
                        as="label"
                        baseTheme="bodySmall"
                        themes={{ large: 'bodyMedium' }}
                    >
                        <span
                            className={styles.locationIcon}
                            onClick={() => initLocation()}
                        >
                            <SvgIcon type="pin" />
                        </span>
                        <input
                            onFocus={() => setShowDropdown(true)}
                            onBlur={() => setShowDropdown(false)}
                            ref={ref}
                            name="search"
                            placeholder={'Enter a location'}
                            type="text"
                            onChange={handleSearch}
                            value={search}
                            autoComplete="off"
                        />
                    </Text>
                    {renderSubmitContainer()}
                </div>

                <div
                    className={cx(styles.dropdown, {
                        [styles.showDropdown]: showDropdown && search !== '',
                    })}
                >
                    {result.length === 0 && search !== undefined ? (
                        <Button
                            className={cx(
                                styles.noResults,
                                styles.dropdownButton
                            )}
                            theme="none"
                        >
                            <Text baseTheme="labelSmall">
                                No results found.
                            </Text>
                        </Button>
                    ) : (
                        result?.map((item, i) => {
                            return (
                                <button
                                    className={styles.dropdownButton}
                                    key={i}
                                    onMouseDown={() =>
                                        pathname === '/where-to-eat'
                                            ? setSearch(item.place_name)
                                            : handleSubmit({
                                                  lat: result[i]?.center[1],
                                                  lon: result[i]?.center[0],
                                                  input: item.place_name,
                                                  type: item?.place_type,
                                              })
                                    }
                                >
                                    <SvgIcon
                                        className={styles.dropdownIcon}
                                        type="pin"
                                    />
                                    <Text
                                        className={styles.dropdownText}
                                        baseTheme="bodyXSmall"
                                        themes={{ medium: 'bodySmall' }}
                                    >
                                        {item.place_name}
                                    </Text>
                                </button>
                            );
                        })
                    )}
                </div>
            </div>
        </>
    );
};

Search.propTypes = {
    className: PropTypes.string,
    userLocation: PropTypes.object,
};

export default Search;
