'use client';

import React from 'react';

import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';
import Video from 'components/ui/Video';

import styles from './CommitmentHero.module.scss';

const CommitmentHero = ({
    videoTextureMp4,
    videoTextureWebm,
    videoPoster,
    callout,
    currentPage,
    cta,
    richHeadline,
}) => {
    if (currentPage && videoTextureMp4 && videoTextureWebm) {
        return (
            <div className={styles.commitmentSubnav}>
                <Stagger className={styles.aspect}>
                    <Video
                        key={currentPage.slug}
                        poster={videoPoster?.url}
                        sources={[videoTextureWebm.url, videoTextureMp4.url]}
                    />
                    <div className={styles.contentWrapper}>
                        <StaggerChild order={0} className={styles.richHeadline}>
                            {richHeadline && (
                                <Text
                                    as="h1"
                                    baseTheme="displayMedium"
                                    themes={{ large: 'displayXLarge' }}
                                >
                                    {richHeadline.json.content.map(
                                        (line, i) => {
                                            if (line.content[0].value === '') {
                                                return null;
                                            }
                                            return (
                                                <span
                                                    className={styles.line}
                                                    key={i}
                                                >
                                                    {line.content[0].value}
                                                </span>
                                            );
                                        }
                                    )}
                                </Text>
                            )}
                            {!richHeadline && (
                                <Text
                                    baseTheme="displayMedium"
                                    themes={{ large: 'displayXLarge' }}
                                >
                                    {currentPage.title}
                                </Text>
                            )}
                        </StaggerChild>
                    </div>
                    {cta && (
                        <div className={styles.ctaWrapper}>
                            <StaggerChild order={2}>
                                <ButtonCTA className={styles.cta} {...cta} />
                            </StaggerChild>
                        </div>
                    )}
                </Stagger>
                {callout && (
                    <div className={styles.calloutWrapper}>{callout}</div>
                )}
            </div>
        );
    }

    return null;
};

CommitmentHero.propTypes = {
    videoTextureMp4: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
    videoTextureWebm: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
    videoPoster: PropTypes.shape({
        url: PropTypes.string,
    }),
    callout: PropTypes.object,
    cta: PropTypes.object,
    currentPage: PropTypes.shape({
        title: PropTypes.string.isRequired,
        idx: PropTypes.number,
        slug: PropTypes.string.isRequired,
    }),
    prevPage: PropTypes.shape({
        slug: PropTypes.string.isRequired,
    }),
    nextPage: PropTypes.shape({
        slug: PropTypes.string.isRequired,
    }),
    richHeadline: PropTypes.object,
};

export default CommitmentHero;
