'use client';

import React from 'react';

import PropTypes from 'prop-types';

import SubNav from 'app/templates/FoodService/SubNav';

import { HeaderBottom } from 'components/globals/Header/Header';

const FoodServiceContactLayout = ({ blocks = [] }) => {
    return (
        <>
            <HeaderBottom.Source>
                <SubNav
                    category={{
                        title: 'Contact',
                        slug: 'foodservice/contact',
                    }}
                    toggleMode
                />
            </HeaderBottom.Source>
            {blocks}
        </>
    );
};

FoodServiceContactLayout.propTypes = {
    blocks: PropTypes.array,
};

export default FoodServiceContactLayout;
