'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { HeaderBottom } from 'components/globals/Header/Header';
import ProductNav from 'components/globals/ProductNav';

import BlockCallout from 'components/blocks/BlockCallout/BlockCallout';
import CardProductCategory from 'components/cards/CardProductCategory';
import ButtonCTA from 'components/ui/ButtonCTA';
import EntryLink from 'components/ui/Link/EntryLink';

import styles from './ProductCategory.module.scss';

const ProductCategory = ({
    category,
    productNavigation,
    productGridRichTextHeadline,
    categoryDetailIntro,
    productGridEyebrow,
    productGridCtaCard,
    blocksBeforeProducts,
    productGrid,
    blocks,
    whereToBuy,
    firstBlockIsFlushTop,
}) => {
    return (
        <div className={styles.productCategoryPage}>
            {categoryDetailIntro}
            <div className={styles.productCategoryBlocks}>
                {productGridEyebrow && productGridRichTextHeadline && (
                    <div className={styles.callout}>
                        <BlockCallout
                            richHeadline={productGridRichTextHeadline}
                            eyebrow={productGridEyebrow}
                            theme="Banner"
                            colorTheme="Blue Background"
                            hasNotch
                            roundedCorners={false}
                            buttonComponentSlot={
                                productGridCtaCard?.cta?.link &&
                                productGridCtaCard?.cta?.text && (
                                    <ButtonCTA {...productGridCtaCard?.cta} />
                                )
                            }
                        />
                    </div>
                )}
                <HeaderBottom.Source>
                    {productNavigation && category && (
                        <ProductNav
                            {...productNavigation}
                            category={{
                                title: category.title,
                                slug: category.slug,
                                navLabel: category.navLabel,
                            }}
                            navItems={
                                productNavigation.navItemsCollection.items
                            }
                            toggleMode
                        />
                    )}
                </HeaderBottom.Source>
                {blocksBeforeProducts}
                {productGrid && (
                    <article className={styles.productGridFlex}>
                        <ul
                            className={cx(styles.productGrid, {
                                [styles.isRounded]: !firstBlockIsFlushTop,
                            })}
                        >
                            {productGrid.map((card, idx) => {
                                return !card.link.__typename ? (
                                    'DRAFT'
                                ) : (
                                    <li
                                        className={cx(styles.gridCard, {
                                            [styles.fullWidth]: card.fullWidth,
                                        })}
                                        key={idx}
                                    >
                                        <EntryLink entry={card.link}>
                                            <CardProductCategory
                                                title={card.productTitle}
                                                image={card.productImage}
                                                theme={'detail'}
                                                backgroundColor={
                                                    card.backgroundColor
                                                }
                                                fullWidth={card.fullWidth}
                                            />
                                        </EntryLink>
                                    </li>
                                );
                            })}
                        </ul>
                    </article>
                )}
                {blocks}
            </div>
            {whereToBuy}
        </div>
    );
};

ProductCategory.propTypes = {
    category: PropTypes.object,
    productNavigation: PropTypes.object,
    productGridRichTextHeadline: PropTypes.object,
    categoryDetailIntro: PropTypes.object,
    productGridEyebrow: PropTypes.string,
    productGridHeadline: PropTypes.string,
    productGridCtaCard: PropTypes.object,
    blocksBeforeProducts: PropTypes.array,
    productGrid: PropTypes.array,
    blocks: PropTypes.array,
    newsBlock: PropTypes.object,
    whereToBuy: PropTypes.object,
    firstBlockIsFlushTop: PropTypes.bool,
};

export default ProductCategory;
