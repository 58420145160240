'use client';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useClickAway, usePrevious } from 'react-use';

import cx from 'classnames';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';

import ButtonCircle from 'components/ui/ButtonCircle';
import Icon from 'components/ui/Icon';
import EntryLink from 'components/ui/Link/EntryLink';
import Text from 'components/ui/Text';

import useScrollDirection from 'hooks/useScrollDirection';

import styles from './SubNav.module.scss';

export const SubNavContext = React.createContext(false);

const navItems = [
    {
        id: 'home',
        slug: 'stewardship-report',
        title: 'Home',
        thumbnail: {
            file: {
                url: '//images.ctfassets.net/j8tkpy1gjhi5/5OzgoO474tMUAwHKqjuXIi/25d03e649a2caa7a6ed524ce154df741/subnav-home.svg',
            },
        },
    },
    {
        id: 'environmental-action',
        slug: 'stewardship-report/environmental-action',
        title: 'Environmental Action',
        thumbnail: {
            file: {
                url: '//images.ctfassets.net/j8tkpy1gjhi5/1DEbQ9tDPGzAOvRqgBNetd/0db84de236f3c5372aa3873739e9dd6a/environmental_action.svg',
            },
        },
    },
    {
        id: 'on-the-farm',
        slug: 'stewardship-report/on-the-farm',
        title: 'On the Farm',
        thumbnail: {
            file: {
                url: '//images.ctfassets.net/j8tkpy1gjhi5/6kb2F7zOFkAmWldmI6tfYG/2337fc5f1641472790a26e25aef3f1e0/On_the_farm.svg',
            },
        },
    },
    {
        id: 'communities',
        slug: 'stewardship-report/communities',
        title: 'Communities',
        thumbnail: {
            file: {
                url: '//images.ctfassets.net/j8tkpy1gjhi5/48JWj9HelIuqVCynNDrEAX/643a837da881cc698a5f3f3fe4c0cd7f/stewardship_nav-communities.svg',
            },
        },
    },
    {
        id: 'scorecard',
        slug: 'stewardship-report/scorecard',
        contentTypeId: 'page',
        title: 'Scorecard',
        thumbnail: {
            file: {
                url: '//images.ctfassets.net/j8tkpy1gjhi5/5IxlkcwLFRVWrZc4D97z2e/4c4788e7b9043ad14e21ef137e901e71/subnav-scorecard.svg',
            },
        },
    },
];

const Nav = ({ asPath, transition, onTransitionEnd }) => {
    return (
        <nav className={styles.root} onTransitionEnd={onTransitionEnd}>
            {transition !== 'exited' && (
                <div className={styles.rootInner}>
                    {navItems.map((navItem, i) => (
                        <EntryLink
                            key={navItem.id}
                            className={cx(styles.item, {
                                [styles.itemActive]:
                                    asPath === `/${navItem.slug}` ||
                                    (asPath.includes(navItem.slug) &&
                                        navItem.slug !== 'stewardship-report'),
                            })}
                            style={{
                                '--animation-order': i + 1,
                            }}
                            entry={navItem}
                        >
                            <figure className={styles.aspectBox}>
                                <Icon
                                    className={styles.icon}
                                    src={navItem?.thumbnail?.file?.url}
                                />
                            </figure>
                            <Text
                                baseTheme="labelSmall"
                                themes={{ large: 'labelLarge' }}
                                className={styles.label}
                                as="figcaption"
                            >
                                {navItem?.navLabel || navItem.title}
                            </Text>
                        </EntryLink>
                    ))}
                </div>
            )}
        </nav>
    );
};

Nav.propTypes = {
    asPath: PropTypes.string,
    navItems: PropTypes.array.isRequired,
    transition: PropTypes.string,
    onTransitionEnd: PropTypes.func,
};

const SubNav = ({
    category,
    transition: transitionOverride,
    setTransition: setTransitionOverride,
}) => {
    const [transitionInternal, setTransitionInternal] = useState('exited');

    const transition = setTransitionOverride
        ? transitionOverride
        : transitionInternal;
    const setTransition = setTransitionOverride || setTransitionInternal;

    const scrollDirection = useScrollDirection();
    const prevScrollDirection = usePrevious(scrollDirection);

    const pathname = usePathname();
    const prevAsPath = usePrevious(pathname);
    const menuRef = useRef(null);

    const matchedCategory = [...navItems]
        .reverse()
        .find(item => pathname.includes(item.slug));

    const closeMenu = useCallback(() => {
        transition === 'entered' && setTransition('exiting');
    }, [transition, setTransition]);

    useClickAway(menuRef, () => {
        closeMenu();
    });

    useEffect(() => {
        if (prevAsPath !== pathname && typeof prevAsPath !== 'undefined') {
            closeMenu();
        }
    }, [prevAsPath, pathname, closeMenu]);

    useEffect(() => {
        if (
            scrollDirection == 'down' &&
            prevScrollDirection !== scrollDirection
        ) {
            closeMenu();
        }
    }, [scrollDirection, prevScrollDirection, closeMenu]);

    return (
        <div
            key="productNav"
            ref={menuRef}
            className={cx(
                styles.menu,
                transition === 'entered' && styles.isEntered,
                transition === 'exiting' && styles.isExiting,
                transition === 'exited' && styles.isExited
            )}
        >
            <div
                role="button"
                className={styles.menuTrigger}
                onClick={() => {
                    transition !== 'entered'
                        ? setTransition('entered')
                        : setTransition('exiting');
                }}
            >
                <Text baseTheme="labelLarge" className={styles.menuTitle}>
                    2023 Stewardship Report
                </Text>
                <span className={styles.menuCurrentTitle}>
                    <span className={styles.menuCurrentTitleSeparator}>—</span>
                    {matchedCategory?.title ||
                        category?.navLabel ||
                        category?.title}
                </span>
                <ButtonCircle
                    className={cx(styles.buttonCircle, {
                        [styles.isEntered]: transition === 'entered',
                    })}
                    theme="small-fill-creamdark"
                    iconType="chevronDown"
                />
            </div>
            <Nav
                navItems={navItems}
                asPath={pathname}
                transition={transition}
                onTransitionEnd={() => {
                    transition === 'exiting' && setTransition('exited');
                }}
            />
        </div>
    );
};

SubNav.propTypes = {
    category: PropTypes.shape({
        navLabel: PropTypes.string,
        slug: PropTypes.string,
        title: PropTypes.string,
    }),
    transition: PropTypes.string,
    setTransition: PropTypes.func,
};

export default SubNav;
