'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { HeaderBottom } from 'components/globals/Header/Header';

import BlockCallout from 'components/blocks/BlockCallout/BlockCallout';
import CardProductCategory from 'components/cards/CardProductCategory';
import ButtonCTA from 'components/ui/ButtonCTA';
import EntryLink from 'components/ui/Link/EntryLink';

import SubNav from '../SubNav';

import styles from './ProductCategory.module.scss';

const ProductCategory = ({
    category,
    productGridRichTextHeadline,
    categoryDetailIntro,
    productGridEyebrow,
    productGridCtaCard,
    blocksBeforeProducts,
    productGrid,
    blocks,
    productGridFlushBottom,
}) => {
    const isBlockGridEnd = blocks.some(el => el.flushBottom === false);

    return (
        <div className={styles.productCategoryPage}>
            {categoryDetailIntro}
            {productGridEyebrow && productGridRichTextHeadline && (
                <div className={styles.callout}>
                    <BlockCallout
                        richHeadline={productGridRichTextHeadline}
                        eyebrow={productGridEyebrow}
                        theme="Banner"
                        colorTheme="Blue Background"
                        hasNotch
                        roundedCorners={true}
                        buttonComponentSlot={
                            productGridCtaCard?.cta?.link &&
                            productGridCtaCard?.cta?.text && (
                                <ButtonCTA {...productGridCtaCard?.cta} />
                            )
                        }
                    />
                </div>
            )}
            <HeaderBottom.Source>
                <SubNav
                    category={{
                        title: category.title,
                        slug: category.slug,
                        navLabel: category.navLabel,
                    }}
                    toggleMode
                />
            </HeaderBottom.Source>
            {blocksBeforeProducts}
            {productGrid.length > 0 && (
                <article
                    className={cx(styles.productGridFlex, {
                        [styles.flushBottom]: productGridFlushBottom,
                    })}
                >
                    <ul
                        className={cx(styles.productGrid, {
                            [styles.isRounded]: !isBlockGridEnd,
                        })}
                    >
                        {productGrid.map((card, idx) => {
                            return !card.link?.__typename ? (
                                'DRAFT'
                            ) : (
                                <li
                                    className={cx(styles.gridCard, {
                                        [styles.fullWidth]: card.fullWidth,
                                    })}
                                    key={idx}
                                >
                                    <EntryLink entry={card.link}>
                                        <CardProductCategory
                                            title={card.productTitle}
                                            image={card.productImage}
                                            theme={'detail'}
                                            backgroundColor={
                                                card.backgroundColor
                                            }
                                            fullWidth={card.fullWidth}
                                        />
                                    </EntryLink>
                                </li>
                            );
                        })}
                    </ul>
                </article>
            )}
            {blocks}
        </div>
    );
};

ProductCategory.propTypes = {
    category: PropTypes.object,
    productGridRichTextHeadline: PropTypes.object,
    categoryDetailIntro: PropTypes.object,
    productGridEyebrow: PropTypes.string,
    productGridCtaCard: PropTypes.object,
    blocksBeforeProducts: PropTypes.array,
    productGridFlushBottom: PropTypes.bool,
    productGrid: PropTypes.array,
    blocks: PropTypes.array,
};

export default ProductCategory;
