'use client';

import React, { Suspense, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import Search from '../Search';

import styles from './HeroCTA.module.scss';

const HeroCTA = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const content = [
        {
            title: 'for customers',
            icon: 'https://images.ctfassets.net/j8tkpy1gjhi5/1da7pldHow4eQP368EfoQL/5d89a31154199de6b3669b8238415270/Customer.svg',
            renderHeadline: () => (
                <>
                    Restaurant <em>Finder</em>
                </>
            ),
            body: "Try it and you'll get it. Find a restaurant serving Tillamook products near you.",
            renderCta: () => (
                <Suspense fallback={`Loading...`}>
                    <Search />
                </Suspense>
            ),
        },
        {
            title: 'for owners',
            icon: 'https://images.ctfassets.net/j8tkpy1gjhi5/1SxOYYrRS33hGq3IkwM8fS/a7967ddf7c9f0872cbbb6be133a74e0f/Chef.svg',
            renderHeadline: () => (
                <>
                    Join the <em>Family</em>
                </>
            ),
            body: "You love creating delicious dishes using Tillamook products and we want to tell the world. It's easy to add your restaurant.",
            renderCta: () => (
                <ButtonCTA
                    className={styles.cta}
                    text="Add Restaurant"
                    style="filled-blue-ocean"
                    link={{ slug: 'where-to-eat/apply' }}
                />
            ),
        },
    ];

    return (
        <Stagger className={styles.root}>
            <StaggerChild order={0} className={styles.inner}>
                <div className={styles.nav}>
                    {content.map((item, i) => (
                        <button
                            key={i}
                            className={cx(styles.navItem, {
                                [styles.isActive]: activeIndex === i,
                            })}
                            onClick={() => setActiveIndex(i)}
                        >
                            <Icon className={styles.navIcon} src={item.icon} />

                            <Text
                                className={styles.navTitle}
                                baseTheme="labelSmall"
                                themes={{ large: 'labelMedium' }}
                            >
                                {item.title}
                            </Text>
                        </button>
                    ))}
                </div>
                <div key={activeIndex} className={styles.content}>
                    <SvgIcon
                        className={cx(styles.icon, {
                            [styles.hideIcon]: activeIndex === 1,
                        })}
                        type="restaurantUtensils"
                    />

                    <Text
                        as="h1"
                        className={styles.headline}
                        baseTheme="displayXSmall"
                        themes={{
                            medium:
                                activeIndex === 0
                                    ? 'displaySmall'
                                    : 'displayMedium',
                            large: 'displayLarge',
                        }}
                    >
                        {content[activeIndex].renderHeadline()}
                    </Text>
                    <Text
                        className={styles.body}
                        baseTheme="bodySmall"
                        fixWidows
                        themes={{
                            medium: 'bodyMedium',
                            large: 'bodyLarge',
                        }}
                    >
                        {content[activeIndex].body}
                    </Text>
                    {content[activeIndex].renderCta()}
                </div>
            </StaggerChild>
        </Stagger>
    );
};
HeroCTA.propTypes = {
    userLocation: PropTypes.object,
};

export default HeroCTA;
