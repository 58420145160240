'use client';

import React from 'react';

import PropTypes from 'prop-types';

import { HeaderBottom } from 'components/globals/Header/Header';

import SubNav from './SubNav';

const StewardshipReportLayout = ({ blocks = [] }) => {
    return (
        <>
            <HeaderBottom.Source>
                <SubNav toggleMode />
            </HeaderBottom.Source>
            {blocks}
        </>
    );
};

StewardshipReportLayout.propTypes = {
    blocks: PropTypes.array,
};

export default StewardshipReportLayout;
