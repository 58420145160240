'use client';

import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { ThemeProvider } from 'hooks/useTheme';

import styles from './Default.module.scss';

const Default = ({ blocks, theme }) => {
    blocks ??= [];
    theme ??= 'default';

    function isInView(element) {
        const { top, left, bottom, right } = element.getBoundingClientRect();

        return (
            top >= 0 &&
            left >= 0 &&
            bottom <=
                (window.innerHeight || document.documentElement.clientHeight) &&
            right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const handleLoad = () => {
        if (window.location.hash) {
            const id = window.location.hash.replace(/#/g, '');
            const element = document.getElementById(id);

            if (element && !isInView(element)) {
                element.scrollIntoView();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('load', handleLoad);

        return () => {
            window.removeEventListener('load', handleLoad);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles[theme]}>
            <ThemeProvider theme={theme}>{blocks}</ThemeProvider>
        </div>
    );
};

Default.propTypes = {
    blocks: PropTypes.array,
    theme: PropTypes.oneOf(['default', 'makers-reserve']),
};

export default Default;
