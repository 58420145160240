'use client';

import React from 'react';

import PropTypes from 'prop-types';

import styles from './Contact.module.scss';

const Contact = ({ blocks = [] }) => {
    return <div className={styles.root}>{blocks}</div>;
};

Contact.propTypes = {
    blocks: PropTypes.array,
};

export default Contact;
