'use client';

import PropTypes from 'prop-types';

const MakersReserve = ({ blocks }) => {
    return blocks;
};

MakersReserve.propTypes = {
    blocks: PropTypes.array,
};

export default MakersReserve;
