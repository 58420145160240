import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import Link from 'components/ui/Link';
import Text from 'components/ui/Text';

import { getReadableMonth } from 'utils';

import styles from './CardStoryGrid.module.scss';

const CardStoryGrid = ({ title, category, slug, image, date }) => {
    const formattedDate = date ? new Date(date) : null;

    return (
        <article className={styles.root}>
            <Link className={styles.link} href={`/news/${slug}`}>
                <figure className={styles.aspectBox}>
                    {image?.url && (
                        <Img
                            className={styles.image}
                            src={image.url}
                            alt={image.description || ''}
                            width={image?.width}
                            height={image?.height}
                            customSources={[
                                {
                                    breakpoint: 1920,
                                    src: image.url,
                                    imageWidth: 1920 * 0.5,
                                },
                                {
                                    breakpoint: 1440,
                                    src: image.url,
                                    imageWidth: 1440 * 0.5,
                                },
                                {
                                    breakpoint: 1024,
                                    src: image.url,
                                    imageWidth: 1024 * 0.5,
                                },
                                {
                                    breakpoint: 768,
                                    src: image.url,
                                    imageWidth: 768 * 0.75,
                                },
                                {
                                    src: image.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    )}
                </figure>
                <figcaption
                    className={cx(styles.textBlock, date && styles.date)}
                >
                    {category && (
                        <Text
                            baseTheme="labelSmall"
                            themes={{ medium: 'labelLarge' }}
                            className={styles.storySubHeadline}
                            as="h3"
                        >
                            {category}
                        </Text>
                    )}
                    {formattedDate && (
                        <Text
                            baseTheme="bodySmall"
                            themes={{ medium: 'bodyMedium' }}
                            className={styles.storyDate}
                            as="h3"
                        >
                            {`${getReadableMonth(
                                formattedDate.getUTCMonth()
                            )} ${formattedDate.getUTCDate()}, ${formattedDate.getUTCFullYear()}`}
                        </Text>
                    )}
                    <div className={styles.storyHeadlineWrapper}>
                        <Text
                            baseTheme="headingSmall"
                            themes={{ medium: 'headingMedium' }}
                            className={styles.storyHeadline}
                            as="h1"
                        >
                            {title}
                        </Text>
                    </div>
                </figcaption>
            </Link>
        </article>
    );
};

CardStoryGrid.propTypes = {
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    category: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
};

export default CardStoryGrid;
