'use client';

import React, { useContext } from 'react';

import cx from 'clsx';
import PropTypes from 'prop-types';

import Logo from 'components/globals/Logo';
import { PreviousPathnameContext } from 'components/globals/PreviousPathnameProvider';

import Hero from './Hero';

import styles from './Landing.module.scss';

const Landing = ({ blocks = [] }) => {
    const prevPath = useContext(PreviousPathnameContext);
    return (
        <>
            <div
                className={cx(styles.splash, {
                    [styles.showIntro]: !prevPath,
                })}
            >
                <Logo withMorningStar className={styles.logo} />
                <p className={styles.tagline}>
                    Always a good day
                    <br />
                    to dairy.
                </p>
            </div>
            <Hero showIntro={!prevPath} />
            {blocks}
        </>
    );
};

Landing.propTypes = {
    blocks: PropTypes.array,
    contentTypeId: PropTypes.string,
    description: PropTypes.string,
    metaDescription: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string,
                title: PropTypes.string,
            })
        ),
        contentTypeId: PropTypes.string,
    }),
    seoTitle: PropTypes.any,
};

export default Landing;
