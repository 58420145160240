'use client';

import React from 'react';

import PropTypes from 'prop-types';

import { HeaderBottom } from 'components/globals/Header/Header';

import SubNav from './SubNav';

const FoodService = ({ blocks }) => {
    return (
        <div>
            <HeaderBottom.Source>
                <SubNav toggleMode />
            </HeaderBottom.Source>
            {blocks}
        </div>
    );
};

FoodService.propTypes = {
    blocks: PropTypes.array,
};

export default FoodService;
